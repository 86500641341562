import React from 'react'
import { withStyles } from '@material-ui/core'
import NavBar from '../components/NavBar'
import Feed from '../components/Feed'
import Layout from '../components/Layout'

import { graphql } from 'gatsby'

const styles = themes => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
})

const Blog = props => {
  const { data, classes } = props
  const posts = data.allContentfulBlogPost.edges
  return (
    <Layout>
      <NavBar />
      <div className={classes.root}>
        <Feed posts={posts} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AllPosts {
    allContentfulBlogPost(sort: { order: DESC, fields: postDate }) {
      edges {
        node {
          title
          titleUrl
          headerImage {
            file {
              url
            }
          }
          category {
            displayText
            url
          }
          postDate
          bodyText {
            bodyText
          }
        }
      }
    }
  }
`
export default withStyles(styles)(Blog)
